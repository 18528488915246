import React, {useEffect, useState} from 'react'
import Livescoring from './Livescoring'
import SearchPanel from './SearchPanel'
import Plazas from './Plazas'
import axios from 'axios'
import io from 'socket.io-client'

const Index = ({
    limiteLivescoring,
    indexPaths,
    imgPaths,
    competicionesInscripcionCercana,
    counter
}) => {

    const [updatedLivescoring, setUpdatedLivescoring] = useState([])

    useEffect(() => {
        axios.post(indexPaths.livescoringStart)
            .then((liveResponse) => {
                const livescoring = liveResponse.data

                let jornadas = []
                Object.values(livescoring).map(({compId, jornadaId}) => jornadas[compId] = jornadaId)

                const socket = io('https://ws.nextcaddy.com:29920')

                socket.on('new message', (response) => {
                    if (response.message.tipo === "resultado" && Object.keys(jornadas).includes(response.message.competicion.toString())) {
                        orderLivescoring(jornadas[response.message.competicion])
                    }
                })

                setUpdatedLivescoring(livescoring)

                return () => {
                    socket.disconnect()
                }
            })
    }, [])

    const orderLivescoring = (j) => {
        const jornadaKey = "l-" + j

        axios.post(indexPaths.livescoringReload, {
            'jornada': j
        })
        .then((response) => {
            setUpdatedLivescoring(prevUpdatedLivescoring => ({
                ...prevUpdatedLivescoring,
                [jornadaKey]: {
                    ...prevUpdatedLivescoring[jornadaKey],
                    jornadas: response.data[0],
                    total: response.data[1],
                    ordenClaves: response.data[2],
                    ordenPosicion: response.data[3]
                }
            }))
        })
    }

    const renderLivescoring = () => {
        return <>
            {
                Object.values(updatedLivescoring).map((live, i) => {
                    if (live.jornadas) {
                        return <a href={indexPaths.tournament.replace('0', live.compId)} key={i}>
                            <Livescoring
                                jornadas={live.jornadas}
                                total={live.total}
                                jornadaId={live.jornadaId}
                                calculo={live.calculo}
                                jornadasCompatibles={live.jornadasCompatibles}
                                ordenClaves={live.ordenClaves}
                                ordenPosicion={live.ordenPosicion}
                                ordenJornada={live.orden}
                                limite={limiteLivescoring}
                                compId={live.compId}
                                section={"index"}
                                nombreClub={live.nombreClub}
                                nombreCompeticion={live.nombreCompeticion}
                                horaSalida={live.horaSalida}
                            />
                        </a>
                    }
                })
            }
        </>
    }

    const sectionLoader = () => {
        return <div className="loaderContainer">
            <div className="loader"></div>
        </div>
    }

    const formatNumberWithDots = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    return (
        <>
            <div className="fullContainer react-loaded">
            <div id="menu" className="indexMenu menu">
                    <div className="menuLeft">
                        <a>
                            <svg
                                className="logoMenu BIG"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    whiteSpace: "pre",
                                    fillRule: "evenodd",
                                    clipRule: "evenodd",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: 2
                                }}
                                viewBox="0 0 1500 400"
                            >
                                <use href="#logoNXT"></use>
                            </svg>

                            <svg
                                className="logoMenu"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    whiteSpace: "pre",
                                    fillRule: "evenodd",
                                    clipRule: "evenodd",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: 2
                                }}
                                viewBox="0 0 400 400"
                            >
                                <use href="#svgLogo"></use>
                            </svg>
                        </a>
                        <a className="menuItem" href="#sectionToday">
                            HOY
                        </a>
                        <a className="menuItem mobileHidden" href="#club">
                            CLUB
                        </a>
                        <a className="menuItem mobileHidden" href="#jugador">
                            JUGADOR
                        </a>
                        <a className="menuItem" href="#sectionContact">
                            CONTACTO
                        </a>
                    </div>

                    <div className="menuRight">
                        <div className="menuItem">
                            <a href="#sectionSearch">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                    <path
                                        fill="currentColor"
                                        d="m29 27.59-7.55-7.56a11.02 11.02 0 1 0-1.42 1.42L27.6 29ZM4 13a9 9 0 1 1 9 9 9.01 9.01 0 0 1-9-9Z"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="ruleContainer">
                    <section className="ruleVh" id="0"></section>
                    <section className="ruleVh" id="05"></section>
                    <section className="ruleVh" id="1"></section>
                    <section className="ruleVh" id="105"></section>
                    <section className="ruleVh" id="2"></section>
                    <section className="ruleVh" id="205"></section>
                    <section className="ruleVh" id="3"></section>
                    <section className="ruleVh" id="305"></section>
                    <section className="ruleVh" id="4"></section>
                    <section className="ruleVh" id="405"></section>
                    <section className="ruleVh" id="5"></section>
                    <section className="ruleVh" id="505"></section>
                    <section className="ruleVh" id="6"></section>
                    <section className="ruleVh" id="605"></section>
                    <section className="ruleVh" id="7"></section>
                </div>

                {/* Marco */}
                <div className="marco"></div>

                {/* Sidebar */}
                <div id="sideFirst" className="sideFirst mask">
                    { updatedLivescoring.length === undefined ? renderLivescoring() : sectionLoader() }
                </div>

                <div id="logoContainer" className=" logoContainer left">
                    <svg className="cutoutSVG_MAIN" xmlns="http://www.w3.org/2000/svg">
                        <mask id="hole">
                            <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                            <g className="coutoutSVGGroup" transform-origin="50% 0%">
                                <use href="#logoNXT" x="0" y="50%"/>
                            </g>
                        </mask>
                        <rect x="0" y="0" width="100%" height="100%" fill="currentColor" mask="url(#hole)"/>
                    </svg>
                </div>

                <div className=" fullscreen-video-container">
                    <video
                        muted
                        playsInline
                        autoPlay
                        loop
                        src={imgPaths.video}
                        poster={imgPaths.poster}
                        className="ng-star-inserted"
                    >
                    </video>
                </div>
                <div className="scrollCue">
                    <svg width="64" viewBox="0 0 64 64">
                        <path
                            d="M12.6 21.3a5.2 5.2 0 013.7 1.5l15.5 15 15.4-15a5.2 5.2 0 017.4 7.4L31.8 52.7l-23-22.5a5.2 5.2 0 013.8-8.9z"
                            fill="#fff"
                        >
                        </path>
                    </svg>
                </div>

                <div className="spacer  scroll01 stay0">
                    <div className="stickyContainer ">
                        <div className="stickyElement ">
                            <div className="logoHomeMobile">
                                <svg
                                    className="logoHomeMobile"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        whiteSpace: "pre",
                                        fillRule: "evenodd",
                                        clipRule: "evenodd",
                                        strokeLinejoin: "round",
                                        strokeMiterlimit: 2
                                    }}
                                    viewBox="0 0 1500 400"
                                >
                                    <use href="#logoNXT"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="spacer  scroll1 stay105 z10">
                    <div className="stickyContainer ">
                        <div className="stickyElement ">
                            <div className="ticker">
                                <div className="frame">
                                    <div className="customerList">
                                        <div className="looping_tikr customerTicker">
                                            <div className="inner">
                                                <div className="customer"> CANARIAS</div>
                                                <div className="customer"> ANDALUCÍA</div>
                                                <div className="customer"> REAL FEDERACIÓN DE GOLF DE MADRID</div>
                                                <div className="customer"> CASTILLA Y LEÓN</div>
                                                <div className="customer"> ANDALUCÍA</div>
                                                <div className="customer"> MADRID</div>
                                                <div className="customer"> CANARIAS</div>
                                                <div className="customer"> CASTILLA Y LEÓN</div>
                                            </div>
                                        </div>

                                        <div className="looping_tikr customerTicker club ">
                                            <div className="inner">
                                                <div className="customer"> PUERTA DE HIERRO</div>
                                                <div className="customer">SEVILLA GOLF</div>
                                                <div className="customer">PINEDA</div>
                                                <div className="customer">CTG</div>
                                                <div className="customer">ULZAMA</div>
                                                <div className="customer"> LAURO GOLF</div>
                                                <div className="customer">CTG</div>
                                                <div className="customer"> PUERTA DE HIERRO</div>
                                                <div className="customer">SEVILLA GOLF</div>
                                                <div className="customer">BELLAVISTA</div>
                                                <div className="customer">ULZAMA</div>
                                                <div className="customer"> EL BOSQUE</div>
                                                <div className="customer"> PINEDA</div>
                                            </div>
                                        </div>

                                        <div className="looping_tikr customerTicker">
                                            <div className="inner">
                                                <div className="customer"> MADRID</div>
                                                <div className="customer"> CASTILLA Y LEÓN</div>
                                                <div className="customer"> ANDALUCÍA</div>
                                                <div className="customer"> CANARIAS</div>
                                                <div className="customer"> MADRID</div>
                                                <div className="customer"> CASTILLA Y LEÓN</div>
                                                <div className="customer"> ANDALUCÍA</div>
                                                <div className="customer"> CANARIAS</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="spacer scroll4 stay1 z10">
                    <div className="stickyContainer ">
                        <div className="stickyElement">
                            <div id="#FUN" className="copyDetails right">
                                <div className="copyDetailsHeader fun">
                                    <svg
                                        className="logoClub"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        clipRule="evenodd"
                                        height="1em"
                                        width="1em"
                                        viewBox="0 0 1000 1000"
                                        style={{whiteSpace: "pre"}}
                                    >
                                        <use href="#logoNXT_SQ_READY" x="0" y="0"/>
                                    </svg>

                                    <svg
                                        className="logoClub"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{whiteSpace: "pre"}}
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        clipRule="evenodd"
                                        height="1em"
                                        width="1em"
                                        viewBox="0 0 1000 1000"
                                    >
                                        <use href="#logoFUN1" x="0" y="0"/>
                                    </svg>

                                    <svg
                                        className="logoClub"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{whiteSpace: "pre"}}
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        clipRule="evenodd"
                                        height="1em"
                                        width="1em"
                                        viewBox="0 0 1000 1000"
                                    >
                                        <use href="#logoFUN2" x="0" y="0"/>
                                    </svg>

                                    <svg
                                        className="logoClub"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{whiteSpace: "pre"}}
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        clipRule="evenodd"
                                        height="1em"
                                        width="1em"
                                        viewBox="0 0 1000 1000"
                                    >
                                        <use href="#logoFUN3" x="0" y="0"/>
                                    </svg>
                                </div>

                                <div className="copyDetailsContent">
                                    <div className="stats fun">
                                        <div className="stat">
                                            <div className="stNumber">509.895</div>
                                            <div className="stLabel">Birdies</div>
                                        </div>
                                        <div className="stat">
                                            <div className="stNumber">169.393</div>
                                            <div className="stLabel">Eagles</div>
                                        </div>
                                        <div className="stat">
                                            <div className="stNumber">1.884</div>
                                            <div className="stLabel">Hoyos en 1</div>
                                        </div>
                                    </div>
                                    <div className="explainer fun">En tus mejores golpes.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="spacer scroll4 stay0 z20">
                    <div className="stickyContainer ">
                        <div className="stickyElement">
                            <div className="copyBlock fun right">
                                <h1><span className="push">Fun</span> <span className="highLight">@</span> <br/> golf
                                </h1>
                                <div className="text toLoad"> La <span className="highLight"> pasión </span> de mejorar
                                    a tu lado
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="spacer scroll6 stay05 z20">
                    <div className="stickyContainer ">
                        <div className="stickyElement block">
                            <div id="jugador" className="moreInfoBlock fun">
                                <div className="moreInfoWatermark">
                                    <svg xmlns="http://www.w3.org/2000/svg">
                                        <g transform-origin="0% 0%">
                                            <use href="#logoFUN3" x="0" y="0"></use>
                                        </g>
                                    </svg>
                                </div>

                                <div className="introBlock">
                                    <div className="title">
                                        <h1 className="highLight toLoad">
                                            FUN
                                            <div className="and">&amp;</div>
                                            Golf
                                        </h1>
                                    </div>
                                    <div className=" cutoutFloat ">
                                        <svg className="cutoutSVG" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="holeFUN">
                                                <rect x="0" y="0" width="100" height="100" fill="white"></rect>
                                                <g transform="scale(.1 .1)" transform-origin="0% 0%">
                                                    <use href="#logoFUN3" x="0" y="0"></use>
                                                </g>
                                            </mask>
                                            <rect x="0" y="0" width="100" height="100" fill="currentColor"
                                                  mask="url(#holeFUN)">
                                            </rect>
                                        </svg>
                                    </div>
                                </div>

                                <div className="contactar">
                                    <a href="#sectionContact" className="mButton js-magnet">
                                        CONTACTA
                                    </a>

                                    <a className="mButton js-magnet" href="https://chat.nextcaddy.com/">
                                        OPINA!
                                    </a>
                                </div>

                                <div className="featuresBlock">
                                    <div className="featureContainer ">
                                        <details>
                                            <summary>
                                                <div>
                                                    <div>
                                                        <h3>Tu próximo Caddy</h3>
                                                        <div className="featureContainerCatch">
                                                            Información de tu juego cuando la necesitas
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordionArrow">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                                        <path fill="currentColor"
                                                              d="M16 22 6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"/>
                                                    </svg>
                                                </div>
                                            </summary>

                                            <div className="content">
                                                <article>
                                                    <h3>Nos encanta este juego</h3>
                                                    <p>
                                                        Nexcaddy nace de jugadores que querían mejorar su experiencia de
                                                        juego.
                                                    </p>
                                                    <p>
                                                        Este deporte siempre ha sido fantástico, pero las herramientas
                                                        para organizar torneos no siempre lo han sido.
                                                    </p>

                                                    <p>
                                                        Hay herramientas que cumplen con lo imprescindible para enfocar
                                                        la
                                                        competición desde el punto de vista ofimático.
                                                    </p>
                                                    <p>
                                                        Nextcaddy fue la primera que
                                                        devolvió el foco al jugador.
                                                    </p>

                                                    <p>
                                                        Llamar al club para conocer la hora de salida o esperar horas
                                                        para conocer
                                                        los resultados no es divertido.
                                                    </p>

                                                    <p>

                                                        Por eso nace Nextcaddy. Sus soluciones son las más sencillas,
                                                        probadas y
                                                        avanzadas.
                                                    </p>

                                                    <p>
                                                        Que el golf sea divertido no significa que deje de ser serio.
                                                        Nuestra
                                                        experiencia con las
                                                        principales Federaciones asegura que con nuestras herremientas
                                                        el golf siga
                                                        siendo GOLF.
                                                    </p>
                                                </article>

                                                <div className="contentIllustration">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="100%"
                                                        height="100%"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <g
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                        >
                                                            <path
                                                                d="M22 11.429V18a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-1.5m17-5.071V10a2 2 0 0 0-2-2h-1m3 3.429h-3"/>
                                                            <path
                                                                d="M19 8v6.5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2V8Zm0 0H5.5"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </details>

                                        <details>
                                            <summary>
                                                <div>
                                                    <h3>Hazlo tuyo</h3>

                                                    <div className="featureContainerCatch">
                                                        Hecho por jugadores, para jugadores
                                                    </div>
                                                </div>
                                                <div className="accordionArrow">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                                        <path fill="currentColor"
                                                              d="M16 22 6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"/>
                                                    </svg>
                                                </div>
                                            </summary>

                                            <div className="content">
                                                <article>
                                                    <h3>Creciendo contigo</h3>
                                                    <p>
                                                        NextCaddy lleva más de 10 años creciendo de la mano de jugadores
                                                        como tú que
                                                        nos ayudan a mejorar y hacer de nuestra plataforma una parte
                                                        esencial de su
                                                        experiencia en el golf.
                                                    </p>

                                                    <p>El feedback de los golfistas se nota en cada uno de nuestros
                                                        desarrollos.</p>
                                                    <p>Ante todo, en Nexcaddy somos jugadores.</p>

                                                    <p>Si quieres acompañarnos en nuestro viaje, contacta con
                                                        nosotros.</p>
                                                    <p>
                                                        Si tienes alguna idea genial o quieres formar parte del
                                                        proyecto,
                                                        ponte en contacto con nosotros.
                                                    </p>
                                                </article>

                                                <div className="contentIllustration">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="100%"
                                                        height="100%"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <g
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                        >
                                                            <path
                                                                d="M22 11.429V18a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-1.5m17-5.071V10a2 2 0 0 0-2-2h-1m3 3.429h-3"/>
                                                            <path
                                                                d="M19 8v6.5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2V8Zm0 0H5.5"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </details>
                                    </div>

                                    <div className="featureContainer ">
                                        <details>
                                            <summary>
                                                <div>
                                                    <h3>APP de torneos (RFGA)</h3>

                                                    <div className="featureContainerCatch">
                                                        Aplicación gratuita para federados andaluces
                                                    </div>
                                                </div>
                                                <div className="accordionArrow">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                                        <path fill="currentColor"
                                                              d="M16 22 6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"/>
                                                    </svg>
                                                </div>
                                            </summary>

                                            <div className="content">
                                                <article>
                                                    <p>
                                                    La Federación Andaluza ofrece a través de Nextcaddy una aplicación donde
                                                    unificar avisos, notificaciones y próximos torneos.
                                                    </p>
                                                    <div>
                                                        <h4>Tarjeta electrónica</h4>
                                                        <p>
                                                            Aunque puedes acceder vía web o SMS, en la app dispondrás
                                                            siempre de
                                                            la tarjeta electrónica correspondiente al día de juego.
                                                        </p>
                                                    </div>
                                                    <p>
                                                        Las nuevas normas hacen más justa y flexible la asignación de
                                                        hándicap en función de cada campo, tee o modalidad,
                                                        pero puede ser confuso saber con qué ventaja se inicia cada
                                                        vuelta!
                                                    </p>
                                                    <p>
                                                        Accede rápidamente a tu hándicap de juego con el porcentaje
                                                        correspondiente
                                                        a cada barra y modalidad.
                                                    </p>
                                                    <h4>Otros clubes y federaciones</h4>
                                                    <p>
                                                        Si estás interesado en que tu Federación o Club tenga nuestra
                                                        App, estamos
                                                        encantados de hacerlo! Contacta con tu club o déjanos un mensaje
                                                        en nuestro
                                                        formulario de contacto.
                                                    </p>
                                                </article>

                                                <div className="contentIllustration">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <g fill="currentColor">
                                                            <path
                                                                d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                                                            <path d="M16 3a3 3 0 1 1-6 0a3 3 0 0 1 6 0z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer scroll01 stay2 z10">
                <div className="stickyContainer ">
                    <div className="stickyElement ">
                        <div className="copyDetails right ">
                            <div className="logoDetailsMobile">
                                <svg
                                    className="logoDetailsMobile"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        whiteSpace: "pre",
                                        fillRule: "evenodd",
                                        clipRule: "evenodd",
                                        strokeLinejoin: "round",
                                        strokeMiterlimit: 2
                                    }}
                                    viewBox="0 0 1500 400"
                                >
                                    <use href="#logoNXT"></use>
                                </svg>
                            </div>
                            <div className=" copyDetailsHeader">
                                <div className="filler"></div>
                                <svg className="cutoutSVG" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="holeA">
                                        <rect x="0" y="0" width="100" height="100" fill="white"/>
                                        <g transform="scale(.1 .1)" transform-origin="0% 0%">
                                            <use href="#logoFGA" x="0" y="0"/>
                                        </g>
                                    </mask>

                                    <rect x="0" y="0" width="100%" height="100" fill="currentColor" mask="url(#holeA)"/>
                                </svg>
                                <div className="filler"></div>
                                <svg className="cutoutSVG" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="holeB">
                                        <rect x="0" y="0" width="100" height="100" fill="white"/>
                                        <g transform="scale(.1 .1)" transform-origin="0% 0%">
                                            <use href="#logoPDH" x="0" y="0"/>
                                        </g>
                                    </mask>

                                    <rect x="0" y="0" width="100%" height="100%" fill="currentColor"
                                          mask="url(#holeB)"/>
                                </svg>

                                <div className="filler"></div>
                                <svg className="cutoutSVG" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="holeD">
                                        <rect x="0" y="0" width="100" height="100" fill="white"/>
                                        <g transform="scale(.1 .1)" transform-origin="0% 0%">
                                            <use href="#logoFGOLFCYL" x="0" y="0"/>
                                        </g>
                                    </mask>
                                    <rect x="0" y="0" width="100" height="100" fill="currentColor" mask="url(#holeD)"/>
                                </svg>

                                <div className="filler"></div>
                                <svg className="cutoutSVG" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="holeC">
                                        <rect x="0" y="0" width="100" height="100" fill="white"/>
                                        <g transform="scale(.1 .1)" transform-origin="0% 0%">
                                            <use href="#logoFGM_TEXT" x="0" y="0"/>
                                        </g>
                                    </mask>

                                    <rect x="0" y="0" width="100" height="100" fill="currentColor" mask="url(#holeC)"/>
                                </svg>
                                <div className="filler"></div>
                            </div>

                            <div className="copyDetailsContent">
                                <div className="stats">
                                    <div className="stat">
                                        <div className="stNumber">{ formatNumberWithDots(counter.tournaments) }</div>
                                        <div className="stLabel">Torneos</div>
                                    </div>

                                    <div className="stat">
                                        <div className="stNumber">{ formatNumberWithDots(counter.players) }</div>
                                        <div className="stLabel">Jugadores</div>
                                    </div>
                                </div>
                                <div className="explainer">En los principales clubes y federaciones.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="spacer scroll01 stay05 z20">
                <div className="stickyContainer ">
                    <div className="stickyElement">
                        <a href="#club" className="copyBlock serious right">
                            <h1><span className="push">Serious</span> <span className="highLight">@</span> <br/> golf
                            </h1>
                            <div className="text toLoad">
                                La <span className="highLight"> experiencia </span> de jugar con los mejores
                            </div>

                            <div className="moreInfoWatermark">
                                <svg xmlns="http://www.w3.org/2000/svg">
                                    <g transform-origin="0% 0%">
                                        <use href="#logoFUN3" x="0" y="0"></use>
                                    </g>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div id="xx" className="spacer scroll2 stay05 z20">
                <div className="stickyContainer ">
                    <div className="stickyElement block">
                        <div id="club" className="moreInfoBlock serious">
                            <div className="moreInfoWatermark">
                                <svg xmlns="http://www.w3.org/2000/svg">
                                    <g transform-origin="0% 0%">
                                        <use href="#logoNXT_SQ_READY" x="0" y="0"></use>
                                    </g>
                                </svg>
                            </div>

                            <div className="introBlock">
                                <div className="title">
                                    <h1 className="highLight toLoad">Clubes
                                        <div className="and">&amp;</div>
                                        Federaciones
                                    </h1>
                                </div>
                                <div className=" cutoutFloat ">
                                    <svg className="cutoutSVG" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="holeNX">
                                            <rect x="0" y="0" width="100" height="100" fill="white"></rect>
                                            <g transform="scale(.1 .1)" transform-origin="0% 0%">
                                                <use href="#logoNXT_SQ_READY" x="0" y="0"></use>
                                            </g>
                                        </mask>
                                        <rect x="0" y="0" width="100" height="100" fill="currentColor"
                                              mask="url(#holeNX)"></rect>
                                    </svg>
                                </div>
                            </div>

                            <div className="contactar">
                                <a href="#sectionContact" className="mButton js-magnet">
                                    CONTACTAR
                                </a>
                            </div>

                            <div className="featuresBlock">
                                <div className="featureContainer ">
                                    <details>
                                        <summary>
                                            <div>
                                                <h3>Inscripciones Online
                                                </h3>

                                                <div className="featureContainerCatch">
                                                    Pagos, devoluciones y listas de espera automáticas.
                                                </div>
                                            </div>
                                            <div className="accordionArrow">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                                    <path fill="currentColor"
                                                          d="M16 22 6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"/>
                                                </svg>
                                            </div>
                                        </summary>

                                        <div className="content">
                                            <article>
                                                <h3>Inscripción Online</h3>

                                                <p>
                                                    La inscripción marca el inicio de la experiencia del jugador en el
                                                    torneo. Para el Club, también define los recursos y
                                                    procedimientos necesarios para la organización.
                                                </p>
                                                <p>
                                                    La inscripción online eliminan costes económicos y organizativos
                                                    de no sows, errores humanos y evita horas de trabajo trabajo
                                                    ineficiente.

                                                    Procesos complejos como las listas de espera o gestión de cobros
                                                    y
                                                    devoluciones, se convierten en
                                                    procedimientos
                                                    seguros, automatizados mediante años de
                                                    experiencia.
                                                </p>
                                                <h3>Un piloto Automático</h3>
                                                <p>
                                                    Control automático de tarifas para socios, vouchers, datos del
                                                    jugador,
                                                    restricciones
                                                    de inscripción, políticas de privacidad de club y sponsors y el
                                                    ciclo de vida del torneo.
                                                </p>
                                            </article>

                                            <div className="contentIllustration">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="100%"
                                                    height="100%"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <g
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    >
                                                        <path
                                                            d="M22 11.429V18a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-1.5m17-5.071V10a2 2 0 0 0-2-2h-1m3 3.429h-3"/>
                                                        <path
                                                            d="M19 8v6.5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2V8Zm0 0H5.5"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </details>
                                </div>

                                <div className="featureContainer ">
                                    <details>
                                        <summary>
                                            <div>
                                                <h3>Gestión de Torneos</h3>
                                                <div className="featureContainerCatch">
                                                    La mejor tarjeta. El mejor equipo.
                                                </div>
                                            </div>
                                            <div className="accordionArrow">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                                    <path fill="currentColor"
                                                          d="M16 22 6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"/>
                                                </svg>
                                            </div>
                                        </summary>

                                        <div className="content">
                                            <article>
                                                Con más de 50.000 torneos oficiales, Nextcaddy ofrece las herramientas
                                                más
                                                potentes para una gestión de torneos sólida y eficiente.
                                                <h4>Tarjeta electrónica</h4>
                                                <p>
                                                    Seriamente necesaria.
                                                    Nacida como necesidad de las federaciones para mantener la
                                                    competición, el
                                                    ahorro de tiempo y recursos que supone a los clubes ha acelerado su
                                                    implantación,
                                                    mejorando la experiencia de los jugadores.
                                                </p>
                                                <p>
                                                    Como todas las soluciones de la plataforma, desde la creación de
                                                    horarios y
                                                    clasificaciones hasta la mejor tarjeta electrónica, se ha diseñado
                                                    para
                                                    ofrecer máxima sencillez de uso y el cumplimiento estricto del
                                                    reglamento y el
                                                    espíritu del golf.
                                                </p>
                                                <h4>Soporte y desarrollo contínuos</h4>
                                                <p>
                                                    Nextcaddy cuenta con un equipo propio de desarrollo y soporte
                                                    disponibles
                                                    para apoyar la organización del torneo.
                                                </p>
                                            </article>

                                            <div className="contentIllustration">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                    <path
                                                        fill="currentColor"
                                                        d="M23 7h4v4h-4zm0 6h4v4h-4zm-6-6h4v4h-4zm0 6h4v4h-4z"
                                                    />
                                                    <circle cx="14.5" cy="24.5" r="1.5" fill="currentColor"/>
                                                    <path
                                                        fill="currentColor"
                                                        d="M21 30H8a2.002 2.002 0 0 1-2-2V4a2.002 2.002 0 0 1 2-2h13v2H8v24h13v-8h2v8a2.002 2.002 0 0 1-2 2Z"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </details>

                                </div>

                                <div className="featureContainer ">
                                    <details>
                                        <summary>
                                            <div>
                                                <h3>Comunicación</h3>

                                                <div className="featureContainerCatch">
                                                    Devolviendo el foco al cliente.
                                                </div>
                                            </div>
                                            <div className="accordionArrow">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                                    <path fill="currentColor"
                                                          d="M16 22 6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"/>
                                                </svg>
                                            </div>
                                        </summary>

                                        <div className="content">
                                            <article>
                                                <h4>El foco, el jugador</h4>
                                                <p>
                                                    Un torneo de golf es un diálogo continuo entre la organización y los
                                                    jugadores, esencial para el éxito del evento y el uso eficiente de
                                                    los recursos del club.

                                                    Hoy en día, no basta con un teléfono o un tablón de anuncios.
                                                </p>

                                                <p>
                                                    Nextcaddy coloca al jugador en el centro del torneo.
                                                </p>

                                                <p>
                                                    Un torneo debe fidelizar desde la experiencia del jugador.
                                                    Todas las herramientas de Nextcaddy, desde horarios hasta el
                                                    livescoring,
                                                    parten de esta premisa y ayudan al club a comunicar de forma
                                                    sólida, seria y consistente.
                                                </p>

                                                <h4>SMS y Notificaciones</h4>

                                                <p>
                                                    A través de la App específica o mediante mensajes SMS, el jugador
                                                    permanece
                                                    informado de horarios, resultados e información de cada prueba.
                                                </p>
                                            </article>

                                            <div className="contentIllustration">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                    <path fill="currentColor"
                                                          d="M17.74 30L16 29l4-7h6a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h9v2H6a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4h-4.84Z"/>
                                                    <path fill="currentColor" d="M8 10h16v2H8zm0 6h10v2H8z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </details>
                                </div>

                                <div className="featureContainer ">
                                    <details>
                                        <summary>
                                            <div>
                                                <h3>Desarrollo a Medida</h3>

                                                <div className="featureContainerCatch">
                                                    Un fitting tecnológico para los mejores.
                                                </div>
                                            </div>
                                            <div className="accordionArrow">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                                    <path fill="currentColor"
                                                          d="M16 22 6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"/>
                                                </svg>
                                            </div>
                                        </summary>

                                        <div className="content">
                                            <article>
                                                <h4>A medida</h4>
                                                <p>
                                                    Para que torneos y circuitos no sólo se gestionen con seriedad, sino
                                                    que
                                                    también
                                                    implementen las necesidades de cada Club o Federación, Nextcaddy
                                                    ofrece del
                                                    mejor equipo de desarrollo, formado en la en la colaboración para el
                                                    diseño
                                                    de
                                                    soluciones con sus clientes.
                                                </p>

                                                <h4>Más allá del estándar</h4>
                                                <p>
                                                    Nuestro trabajo con diferentes federaciones ha permitido incorporar
                                                    utilidades en la planificación de calendarios, planificación de
                                                    gastos, retransmisión
                                                    del torneo y otras muchas áreas complementarias.
                                                </p>

                                                <p>
                                                    Estos desarrollos evolucionan y se adaptan contínuamente a las
                                                    necesidades
                                                    más exigentes.
                                                </p>
                                            </article>

                                            <div className="contentIllustration">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                    <path fill="currentColor"
                                                          d="M26 2h4v4h-4zm0 6h4v4h-4zm-6-6h4v4h-4zm0 6h4v4h-4z"/>
                                                    <path fill="currentColor"
                                                          d="M28 16v6H4V6h12V4H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8v4H8v2h16v-2h-4v-4h8a2 2 0 0 0 2-2v-6ZM18 28h-4v-4h4Z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </details>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="sectionSearch" className="height-free">
                {
                    <SearchPanel
                        searchPath={indexPaths.search}
                        tournament={indexPaths.tournament}
                        club={indexPaths.club}
                    />
                }
            </section>

            <section id="sectionToday" className="height-free">
                <div className="inscripciones">
                    <div className="todayTitles">
                        Inscripciones
                    </div>
                    <div className="openCards">
                        {
                            competicionesInscripcionCercana.map((compInsCercana, i) => {
                                return <a href={indexPaths.tournament.replace('0', compInsCercana.compId)} key={i}>
                                    <Plazas
                                        nombreCompeticion={compInsCercana.nombreCompeticion}
                                        nombreClub={compInsCercana.nombreClub}
                                        fechaCompeticion={compInsCercana.fechaCompeticion}
                                        fechaFin={compInsCercana.fechaFin}
                                        plazasTotales={compInsCercana.plazasTotales}
                                        plazasOcupadas={compInsCercana.plazasOcupadas}
                                        listaEspera={compInsCercana.listaEspera}
                                        seccion={"index"}
                                    />
                                </a>
                            })
                        }
                    </div>
                </div>
                <div className={"livePanelArea" + (updatedLivescoring.length !== undefined ? " livePanelLoading" : "") }>
                    <div className="todayTitles">
                        Resultados
                    </div>
                    <div className="livePanel">
                        { updatedLivescoring.length === undefined ? renderLivescoring() : sectionLoader() }
                    </div>
                </div>
            </section>

        </>
    )
}

export default Index
